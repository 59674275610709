import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import "./LegalMentions.scss";

export const LegalMentionsTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="LegalMentions">
      <h1>Mentions légales</h1>
      <PageContent className="Markdown" content={content} />
    </section>
  );
};

LegalMentionsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const LegalMentions = ({ data = {} }) => {
  const { markdownRemark: post } = data || {};

  return (
    <Layout>
      <section className="Layout__body">
        <LegalMentionsTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          content={post.html}
        />
      </section>
    </Layout>
  );
};

LegalMentions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LegalMentions;

export const legalMentionsQuery = graphql`
  query LegalMentions($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
